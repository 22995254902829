@import '~materialize-css/sass/components/_color-variables.scss';

$primary-color: #308cb3 !default;
$secondary-color: #1F5A73 !default;

$text-color: color('grey', 'lighten-3') !default;
$page-bg-color: color('grey', 'darken-4') !default;
$card-bg-color: lighten($page-bg-color, 3%) !default;

$sidenav-bg-color: $card-bg-color;
$sidenav-font-color: $text-color;

$input-disabled-solid-color: color('grey', 'darken-3');
$button-disabled-background: $input-disabled-solid-color;
$input-disabled-color: color('grey', 'darken-1');
$button-disabled-color: $input-disabled-color;
$input-border-color: lighten($card-bg-color, 10%);

$sidenav-z: 999;

@import '~materialize-css/sass/materialize.scss';

@import 'scss/table';

.block {
  display: block;
  width: 100%;
}

.full-splash {
  $background-opacity: 0.1;

  background: linear-gradient(135deg,
          rgba($primary-color, 0.6) 0%,
          rgba($primary-color, 0.1) 100%
  ),
  linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4)),
  linear-gradient(60deg,
                  rgba($primary-color-dark, 0.1) 0%,
                  rgba($primary-color-dark, 0.1) 50%,
                  rgba($primary-color-dark, 0.0) 50.1%
  ),
  black;

  form, .form {
    img {
      width: 150px;
      opacity: 0.9;
      margin: 0 auto 1.5rem auto;
      display: block;
    }

    .card {
      background-color: rgba(darken($primary-color-dark, 25%), 0.75);

      .card-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.primary-text {
  color: $primary-color !important;
}

.primary {
  background-color: $primary-color !important;
}

.primary-dark {
  background-color: $primary-color-dark !important;
}

.primary-light {
  background-color: $primary-color-light !important;
}

input, textarea {
  color: $text-color;
}

.primary-light-text {
  color: $primary-color-light !important;
}

.primary-dark-text {
  color: $primary-color-dark !important;
}

html {
  background-color: $page-bg-color;
  color: $text-color;
}

.App > nav {
  position: fixed;
  top: 0;
  z-index: $sidenav-z + 1;
  background-color: $primary-color-dark;

  .status {
    height: $navbar-height;
    margin-left: $sidenav-width;
    padding-left: 1.5rem;
    font-size: 1.2rem;
    float: left;
  }

  .brand-logo {
    width: $sidenav-width;
    background-color: $primary-color;
    height: $navbar-height;

    img {
      display: block;
      margin: ($navbar-height - 18px) / 2 auto 0;
    }
  }
}

.App main {
  padding-top: $navbar-height;
}

.sidenav {
  margin-top: $navbar-height;
  z-index: $sidenav-z;
  $background-opacity: 0.6;

  .background {
    background-color: $primary-color-dark;
    background: black radial-gradient(circle at 20px -10px,
            rgba($primary-color, $background-opacity) 0%,
            rgba($primary-color, $background-opacity) 60%,
            rgba($primary-color, $background-opacity + 0.2) 60.1%
    );

    img {
      height: 100%;
      float: right;
      padding: 1rem;
      opacity: 0.2;
    }
  }

  .subheader {
    color: darken($sidenav-font-color, 50%);
  }

  .divider {
    background-color: lighten($sidenav-bg-color, 10%);
  }

  li > a > i.material-icons.prefix {
    color: darken($sidenav-font-color, 50%);
  }

  li > a.primary-dark > i.material-icons.prefix {
    color: $primary-color-light;
  }

  li > a:hover {
    background-color: lighten($sidenav-bg-color, 5%);
  }
}

.App > .content {
  margin-left: $sidenav-width;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.no-nav {
    .sidenav {
      margin-top: 0;
    }

    main {
      padding-top: 0;
    }
  }
}

ul.tabs {
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  border-bottom: 1px solid $primary-color-dark;

  li.tab {
    flex-grow: 1;

    a {
      padding: 0 1rem;
    }
  }
}

label:not(.active) {
  color: rgba(255, 255, 255, 0.7) !important;
}

input {
  border-bottom-color: rgba(255, 255, 255, 0.7) !important;
}

input:active, input:focus {
  border-bottom-color: $primary-color-light !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
  -webkit-text-fill-color: white;
  animation: autofill 0s forwards;
}

@keyframes autofill {
  100% {
    background: transparent !important;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent !important;
  }
}

label.active {
  color: $primary-color-light !important;
}
